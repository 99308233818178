<template>
<div id="upload-music" class="big-m-t d-f jc-c p">
  <div class="small-wrap m-b-children">

    <FileUpload 
    @fileData="audioUpload"
    :fileType="'audio'"
    :minSize="1"
    :maxSize="6291456"
    :uploadValue="audioUploadValue"
    :fileId="'songfile'"
    />

    <FileUpload 
    @fileData="imgUpload"
    :fileType="'image'"
    :minSize="1"
    :maxSize="3250586"
    :uploadValue="imgUploadValue"
    :fileId="'imagefile'"
    />

    <form class="m-b-children">
        <div class="input-container">
          <label class="label" for="title">Title</label>
          <input type="text" id="title" name="title" v-model="title" placeholder="Title"/>
        </div>

        <div class="custom-select">
        <label for="genre">
        <select id="genre" v-model="genreSelected">
            <option :value="null" hidden disabled>Select Genre</option>
            <option v-for="(x, i) in genreOptions" :value="x" :key="i">
            {{ x }}
            </option>
        </select>
        </label>
        </div>
        
        
        <!-- <span>Selected: {{ genreSelected }}</span> -->
    </form>

    <div class="d-f jc-c" v-if="audioData && imgData && title && genreSelected && !hideUploadBtn">
      <button class="w-100" @click="onUpload2">Upload</button>
    </div>

    <div class="d-f jc-c" v-if="isLoading">
      <span class="loading-dots">Uploading</span>
    </div>
    
    <div v-if="uploadValue">
        {{uploadValue}}
    </div>

    <!-- <div class="upload-wrap">
      <div >
        <h3>Upload music:</h3>
        <input type="file" @change="previewFile" accept="audio/*" >
      </div>
      <div>
        <p>Progress: {{ uploadValue.toFixed() + "%" }}
          <progress id="progress" :value="uploadValue" max="100" ></progress>
        </p>
      </div>
      <div v-if="fileData != null">
        <button @click="onUpload">Upload</button>
      </div>
    </div> -->
  </div>
</div>
</template>

<script>
import { rtdb, storage } from '@/db.js'
import { mapGetters } from "vuex"
import FileUpload from "@/components/mymusic/FileUpload";

export default {
  name: 'UploadMusic',
  components: {
    FileUpload
  },
  data() {
	  return {
        audioData: null,
        file: null,
        uploadValue: 0,

        title: null,
        genreSelected: null,
        genreOptions: [],

        imgData: null,

        audioUrl: null,
        imgUrl: null,
        imgUploadValue: 0,
        audioUploadValue: 0,

        hideUploadBtn: false,
        isLoading: false
	  }
  },
  mounted() {
    this.getGenres()
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings'
    ]),
    isDisabled() {
      if(this.title != null) {
        return true
      }
    }
  },
  methods:{
    imgUpload(x) {
      return this.imgData = x
    },

    getGenres() {
      const genresRef = rtdb.ref('genres').child('dropdown')
      genresRef.once('value', (snapshot) => {
        const arr = []
        snapshot.forEach(x => {
          arr.push(x.key)
        })
        return this.genreOptions = arr
      })
    },

    audioUpload(x) {
    //   this.uploadValue = 0
    //   this.file = null
      return this.audioData = x
    },

    onUpload2(){
        this.file = null
        if (
          this.audioData != null &&
          this.imgData != null &&
          this.title != null &&
          this.genreSelected != null
        ) {

            this.hideUploadBtn = true
            this.isLoading = true

            //1. Create UID ref of database node
            const musicRef = rtdb.ref('userMusic').child(this.getUser.data.uid)
            const songSettings = {
                userUid: this.getUser.data.uid,
                artist: this.getUserSettings.username,
                title: this.title,
                genres: {
                    gen0: 'All Genres',
                    gen1: this.genreSelected
                },
                album: '',
            }
            const pushKey = musicRef.push(songSettings)
            var keyRef = pushKey.getKey()

            //2. Upload audio to storage and get URL
            const storageRef = storage.ref(`userMusic/${this.getUser.data.uid}/${keyRef}/${this.audioData.name}`).put(this.audioData)
            storageRef.on(`state_changed`, 
                (snapshot) => {
                this.audioUploadValue = (snapshot.bytesTransferred/snapshot.totalBytes) * 100
                }, 
                (error) => { console.log(error.message) },
                () => { this.audioUploadValue = 100
                this.audioUrl = storageRef.snapshot.ref.getDownloadURL()
                return storageRef.snapshot.ref.getDownloadURL()
                .then((passAudioUrl) => {
                    //3. Upload image to storage and get URL
                    const passAudioUrlLevel2 = passAudioUrl
                    const storageImgRef = storage.ref(`userMusic/${this.getUser.data.uid}/${keyRef}/${this.imgData.name}`).put(this.imgData)
                    storageImgRef.on(`state_changed`, 
                        (snapshot) => {
                        this.imgUploadValue = (snapshot.bytesTransferred/snapshot.totalBytes) * 100
                        }, 
                        (error) => { console.log(error.message) },
                        () => { this.imgUploadValue = 100
                        this.imgUrl = storageImgRef.snapshot.ref.getDownloadURL()
                        return storageImgRef.snapshot.ref.getDownloadURL()
                        .then((passImgUrl) => {
                            //4. Update database node with UID and URL's
                            musicRef.child(keyRef).update({
                                    songUid: keyRef,
                                    audio: passAudioUrlLevel2,
                                    cover: passImgUrl
                            }).then(()=>{ 
                              console.log('Upload complete'); 
                              //4. Update rankSongs node with UID and URL's
                              songSettings['audio'] = passAudioUrlLevel2
                              songSettings['cover'] = passImgUrl
                              songSettings['songUid'] = keyRef
                              rtdb.ref('rankSongs').child(keyRef).update({
                                      songUid: keyRef,
                                      songSettings
                              }).then(()=>{ 
                                console.log('rankSongs updated'); 
                                this.isLoading = false
                                this.$router.go(this.$router.currentRoute)

                              }).catch((err) => { console.log(err); alert('Ops! Something went wrong.') })
                            }).catch((err) => { console.log(err); alert('Ops! Something went wrong.') })

                        }).catch((err) => { console.log(err); alert('Ops! Something went wrong.') })
                    })
                }).catch((err) => { console.log(err); alert('Ops! Something went wrong.') })
            })
        } else {
          alert('Missing fields')
        }
      
    }

    // onUpload(){
    //   this.file = null

    //   if (this.fileData.size > 6291456){
    //     alert("File is too big!");
    //   } else {
    //     const storageRef = storage.ref(`userMusic/${this.getUser.data.uid}/${this.fileData.name}`).put(this.fileData)
    //     const musicRef = rtdb.ref('userMusic').child(this.getUser.data.uid)
    //     if (this.title != null && this.genreSelected != null) {
    //       storageRef.on(`state_changed`, 
    //         (snapshot) => {
    //           this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes) * 100
    //         }, 
    //         (error) => { console.log(error.message) },
    //         () => { this.uploadValue = 100
    //         storageRef.snapshot.ref.getDownloadURL()
    //         .then((url) => {
    //           this.file = url
    //           const songSettings = {
    //               userUid: this.getUser.data.uid,
    //               artist: this.getUserSettings.username,
    //               title: this.title,
    //               audio: url,
    //               cover: '',
    //               genres: {
    //                 gen0: 'All Genres',
    //                 gen1: this.genreSelected
    //               },
    //               album: '',
    //           }
    //           const pushKey = musicRef.push(songSettings)
              
    //           var keyRef = pushKey.getKey()

    //           musicRef.child(keyRef).update({
    //                 songUid: keyRef
    //           }).then(()=>{ console.log('Upload complete'); }).catch((err) => { console.log(err) })

    //           rtdb.ref('rankSongs').child(keyRef).update({
    //                 songUid: keyRef,
    //                 songSettings
    //           }).then(()=>{ console.log('rankSongs updated'); }).catch((err) => { console.log(err) })

    //         }).catch((err) => { console.log(err) })
    //       })
    //     } else {
    //       alert('Missing fields')
    //     }
    //   }
    // }

  }
}
</script>

<style lang="scss" scoped>
</style>