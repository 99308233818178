<template>
  <div id="my-music" class="big-m-t big-m-b">
    <Playlist
      :playlist-position="0" 
      :playlist="uploadsList"
      :listType="'songs'"
      :editButtons="true"
      :title="'My Tracks'"
      :subTitle="listCount + ` Tracks`"
      :view="'list'"
      >
    </Playlist>

    <!-- Multiple playlists test -->
    <!-- <SongsList
      :playlist-position="0" 
      :playlist="playlist2">
    </SongsList> -->

    <!-- {{activePlayer}} -->

  </div>
</template>

<script>
import { rtdb } from '@/db.js'
import { mapGetters } from "vuex"
import Playlist from '@/components/playlists/Playlist.vue'

export default {
  name: 'MyMusic',
  components: {
    Playlist
  },
  data() {
	  return {
      playlistPost: null,
      uploadsList: {
        title: 'My Music',
        songs: null,
      },
      playlist2: {
        title: 'testing multiple playlists',
        songs: [
          { 
            audio: 'https://rorg.z1.fm/d/3f/ti_ft_eminem_-_thats_all_she_wrote_(zv.fm).mp3', 
            artist: 'T.I', 
            title: 'Gurka', 
            album: '', 
            cover: 'https://res.cloudinary.com/djx5h4cjt/image/upload/v1551189593/random/f55abc725080eb05147e45ce3cd406a8.1000x1000x1.jpg' 
          },
          { 
            audio: 'https://dll.z1.fm/music/8/e8/ellie_goulding_feat_diplo__swae_lee_-_close_to_me.mp3', 
            artist: 'Ellie Goulding Feat. Diplo & Swae Lee', 
            title: 'Groda', 
            album: '', 
            cover: 'https://res.cloudinary.com/djx5h4cjt/image/upload/v1551189716/random/ellie-goulding-close-to-me-lg.jpg' 
          },
        ]
      }
	  }
  },
  mounted() {
    this.getUploads()
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings',
    ]),
    listCount() {
      if ( this.uploadsList.songs ) {
        return this.uploadsList.songs.length
      } else {
        return '0'
      }
    }
  },
  methods:{
    getUploads() {
      const usersMusicRef = rtdb.ref(`userMusic/${this.getUser.data.uid}`)
      usersMusicRef.on('value', (snapshot) => {
        const arr = []
        snapshot.forEach(x => {
            arr.push( x.val() )
        })
        this.uploadsList.songs = arr

      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>