<template>
<div id="file-upload">
    <!-- <div>
      <input type="file" @change="onChange" :accept="fileType" />
    </div>
    <div v-if="uploadValue > 0">
      <p>Progress: {{ uploadValue.toFixed() + "%" }}
      <progress id="progress" :value="uploadValue" max="100" ></progress> 
      </p>
    </div>
    <div v-if="picture != null">
      <img class="preview" :src="picture">
    </div> -->

    <div class="file-upload">
      <label class="custom-input" :for="fileId">
          <div class="file-input" v-if="fileType === 'audio'">
            <div v-if="data">{{data.name}}, {{dataSize}}</div>
            <div v-else>
              <span>Upload Audio</span>
              <span class="font-small p-a r-0 t-0 p o-5">(Max: {{calcMaxSize}})</span>
            </div>
          </div>
          <div class="file-input" v-if="fileType === 'image'">
            <div v-if="data">{{data.name}}, {{dataSize}}</div>
            <div v-else>
              <span>Upload Image</span>
              <span class="font-small p-a r-0 t-0 p o-5">(Max: {{calcMaxSize}})</span>
            </div>
          </div>
          <div class="progress-bar" :style="{ width: uploadValue + '%' }"></div>
      </label>
      <input v-if="fileType === 'audio'" type="file" :id="fileId" @change="onChange" accept="audio/*" />
      <input v-if="fileType === 'image'" type="file" :id="fileId" @change="onChange" accept="image/*" />
    </div>
    <div class="file-name d-f fd-c jc-c ai-c" v-if="data != null">
      <p class="p" v-if="uploadValue">
        Uploading: {{ uploadValue.toFixed() + "%" }}
      </p>
    </div>

</div>
</template>

<script>
export default {
  name: 'FileUpload',
  props: [
    'fileType',
    'fileId',
    'minSize',
    'maxSize',
    'uploadValue'
  ],
  data() {
	  return {
      data: null,
      dataSize: null,
      picture: null,
	  }
  },
  computed: {
    calcMaxSize() {
      return this.formatBytes(this.maxSize)
    }
  },
  methods: {
    onChange(event) {
        this.uploadValue = 0
        // this.picture = null
        // this.data = event.target.files[0]
        const file = event.target.files[0]
        const size = event.target.files[0].size
        if (size > this.minSize && size < this.maxSize) {          
          this.$emit('fileData', file)
          console.log('File OK');
          this.data = file
          this.dataSize = this.formatBytes(size)
        } else {
          //file too big or small
          this.$emit('fileData', null)
          alert('Wrong filesize');
        }
    },
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }
}
</script>
<style lang="scss" scoped>
#file-upload {
  .progress-bar {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    // width: 0%;
    height: 100%;
    background-color: white;
    opacity: .2;
  }
  // img.preview {
  //     width: 200px;
  // }
}

</style>