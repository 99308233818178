<template>
  <div id="my-music-page">

    <UploadMusic />
    <MyMusic />

  </div>
</template>

<script>
import UploadMusic from '@/components/mymusic/UploadMusic.vue'
import MyMusic from '@/components/mymusic/MyMusic.vue'

export default {
  name: 'MyMusicPage',
  components: {
    UploadMusic,
    MyMusic
  }
}
</script>
